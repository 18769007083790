@import 'styles/layout-tokens';
@import 'styles/colors-2024';
@import 'styles/mixins/media-queries';

.container {
	background-color: #141414;
	color: $white;

	contain: paint;

	@include section-padding;
}

.container h2 {
	max-width: 18.4ch;

	@include md-up {
		margin-bottom: 3rem;
	}

	margin-bottom: 2.5rem;
}

.container ul {
	display: flex;
	gap: 1.25rem;

	list-style: none;
	margin: 0;

	@include sm-down {
		flex-direction: column;
		gap: 2rem;
	}
}

.slider {
	@include sm-down {
		display: none !important;
	}
}

.mobile_list {
	@include md-up {
		display: none !important;
	}
}

.card img {
	width: 38rem;
	height: 36rem;

	border-radius: 1rem;

	@include sm-down {
		width: 100%;
		height: auto;
		max-height: 25rem;
		object-position: center 25%;
	}
}

.card_content {
	padding: 2rem 0;

	@include sm-down {
		padding: 1rem 0;
	}
}

.card_content h3 {
	margin-bottom: 1rem;
	max-width: 40ch;
}

.buttons {
	display: flex;
	justify-content: flex-end;
	align-items: center;
	gap: 1rem;
	margin-right: 8rem;

	@include sm-down {
		display: none;
	}
}

.buttons button {
	width: 3rem;
	height: 3rem;
	border-radius: $br-circle;

	background-color: #39393b;
	line-height: 0;
}
